<template>
  <div class="contacts">
    <!-- 1 -->
    <div class="title">Контакты</div>
    <!-- 2 -->
    <div class="bgimg"></div>
    <!-- 3 -->
    <div class="sec1">
      <div class="title">{{ sec1title }}</div>
      <div class="adress">
        <span>
          {{ sec1adress }}
        </span>
      </div>
      <div class="phone">
        <span>{{ sec1phone }}</span>
      </div>
      <div class="mail">
        <span>{{ sec1mail }} </span> <br />
      </div>
      <img class="adressimg" src="../assets/contacts/adress.png" alt="" />
      <img class="phoneimg" src="../assets/contacts/phone.png" alt="" />
      <img class="mailimg" src="../assets/contacts/mail.png" alt="" />
    </div>
    <!-- 4 -->
    <div class="sec2">
      <div class="card1">
        <ul>
          <li v-for="sec2card1li in sec2card1list" :key="sec2card1li">
            {{ sec2card1li }}
          </li>
        </ul>
      </div>
      <div class="card2">
        <ul>
          <li v-for="sec2card2li in sec2card2list" :key="sec2card2li">
            {{ sec2card2li }}
          </li>
        </ul>
      </div>
    </div>
    <!-- 5 -->
    <div class="secmap">
      <iframe
        src="https://yandex.ru/map-widget/v1/?um=constructor%3Abda5f0b1053d6d1ee97dcd65aefceb50e0ec5063d50081cf1e4688979e21a213&amp;source=constructor"
        width="100%"
        height="450"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sec1title: "",
      sec1adress: "",
      sec1phone: "",
      sec1mail: "",
      sec2card1list: [],
      sec2card2list: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await fetch("https://cd20321.tmweb.ru/api/articles/");
      const finalRes = await res.json();
      this.sec1title = finalRes[58].text;
      this.sec1adress = finalRes[59].text;
      this.sec1phone = finalRes[60].text;
      this.sec1mail = finalRes[61].text;
      this.sec2card1list = finalRes[62].text.split(";");
      this.sec2card2list = finalRes[63].text.split(";");
    },
  },
};
</script>
<style lang="scss">
@media (min-width: 416px) {
  .contacts {
    .title {
      font-weight: 600;
      font-size: 100px;
      line-height: 144px;
      text-align: center;
    }
    .bgimg {
      background-image: url("../assets/contacts/bg.png");
      background-size: cover;
      height: 538px;
    }
    .sec1 {
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-template-rows: repeat(4, auto);
      gap: 50px;
      margin-top: 110px;
      .title {
        grid-column: 2 / span 2;
        grid-row: 1;
        font-weight: 500;
        font-size: 40px;
        line-height: 58px;
        text-transform: uppercase;
        justify-self: left;
        padding-left: 150px;
      }
      .adress {
        grid-column: 2 / span 2;
        grid-row: 2;
        align-self: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 29px;
      }
      .phone {
        grid-column: 2 / span 2;
        grid-row: 3;
        align-self: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 29px;
      }
      .mail {
        grid-column: 2 / span 2;
        grid-row: 4;
        align-self: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 29px;
      }
      span {
        display: inline-block;
        position: relative;
      }
      span::before {
        content: "";
        position: absolute;
        bottom: 20%;
        left: 0;
        width: 100%;
        height: 25%;
        background-color: #e1edd0;
        z-index: -1;
      }
      .adressimg {
        width: 50px;
        grid-column: 1;
        grid-row: 2;
        justify-self: right;
      }
      .phoneimg {
        width: 50px;
        grid-column: 1;
        grid-row: 3;
        justify-self: right;
      }
      .mailimg {
        width: 50px;
        grid-column: 1;
        grid-row: 4;
        justify-self: right;
      }
    }
    .sec2 {
      display: grid;
      grid-template-columns: repeat(2, 575px);
      grid-template-rows: 356px;
      margin: 110px 0;
      column-gap: 30px;
      justify-content: center;

      ul {
        padding-left: 61px;
        padding-top: 60px;
      }
      ul li {
        list-style-type: none;
        font-weight: 600;
        font-size: 20px;
        line-height: 29px;
        padding-bottom: 40px;
      }
      .card1 {
        background: linear-gradient(258.82deg, #e1edd0 0%, #d9e1cd 58.25%);
        box-shadow: 0px 10px 40px rgba(184, 197, 166, 0.3);
        border-radius: 5px;
      }
      .card2 {
        background: linear-gradient(258.82deg, #e1edd0 0%, #d9e1cd 58.25%);
        box-shadow: 0px 10px 40px rgba(184, 197, 166, 0.3);
        border-radius: 5px;
      }
    }
    .secmap {
      display: flex;
      justify-content: center;
      margin-bottom: 120px;
      .map {
        max-width: 1180px;
      }
    }
  }
}
@media (max-width: 415px) {
  .contacts {
    .title {
      font-weight: 600;
      font-size: 50px;
      line-height: 72px;
      text-align: center;
    }
    .bgimg {
      background-image: url("../assets/contacts/bgm.png");
      background-size: cover;
      height: 516px;
    }
    .sec1 {
      display: grid;
      grid-template-columns: minmax(auto, 50) minmax(auto, 285px);
      grid-template-rows: repeat(4, auto);
      row-gap: 50px;
      column-gap: 30px;
      margin-top: 60px;
      justify-content: center;
      .title {
        grid-column: 1 / span 2;
        grid-row: 1;
        font-weight: 500;
        font-size: 30px;
        line-height: 46px;
        text-transform: uppercase;
        text-align: center;
      }
      .adress {
        grid-column: 2;
        grid-row: 2;
        align-self: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 23px;
      }
      .phone {
        grid-column: 2;
        grid-row: 3;
        align-self: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 23px;
      }
      .mail {
        grid-column: 2;
        grid-row: 4;
        align-self: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 23px;
      }
      span {
        display: inline-block;
        position: relative;
      }
      span::before {
        content: "";
        position: absolute;
        bottom: 20%;
        left: 0;
        width: 100%;
        height: 25%;
        background-color: #e1edd0;
        z-index: -1;
      }
      .adressimg {
        width: 50px;
        grid-column: 1;
        grid-row: 2;
        justify-self: right;
      }
      .phoneimg {
        width: 50px;
        grid-column: 1;
        grid-row: 3;
        justify-self: right;
      }
      .mailimg {
        width: 50px;
        grid-column: 1;
        grid-row: 4;
        justify-self: right;
      }
    }
    .sec2 {
      display: grid;
      grid-template-columns: repeat(1, 335px);
      grid-template-rows: minmax(auto, auto);
      margin: 60px 0;
      row-gap: 30px;
      justify-content: center;

      ul {
        text-align: center;
        padding: 60px 15px 0 15px;
      }
      ul li {
        list-style-type: none;
        font-weight: 600;
        font-size: 20px;
        line-height: 29px;
        padding-bottom: 40px;
      }
      .card1 {
        background: linear-gradient(258.82deg, #e1edd0 0%, #d9e1cd 58.25%);
        box-shadow: 0px 10px 40px rgba(184, 197, 166, 0.3);
        border-radius: 5px;
      }
      .card2 {
        background: linear-gradient(258.82deg, #e1edd0 0%, #d9e1cd 58.25%);
        box-shadow: 0px 10px 40px rgba(184, 197, 166, 0.3);
        border-radius: 5px;
      }
    }
    .secmap {
      display: flex;
      justify-content: center;
      margin-bottom: 120px;
      .map {
        max-width: 334px;
      }
    }
  }
}
</style>
